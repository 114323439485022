import(/* webpackMode: "eager" */ "/vercel/path0/apps/onboarding/public/assets/logos/prismo/light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProviders"] */ "/vercel/path0/apps/onboarding/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/AppBar/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-axiom@1.4.1_next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_re_edcoqycuygyvvckpm7oaph7r5y/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/onboarding/src/app/[locale]/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HubspotProvider"] */ "/vercel/path0/packages/features/auth/src/hooks/use-hubspot/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientFlagsProvider"] */ "/vercel/path0/packages/flags/src/client/client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/create-use-flag.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-admins-toolers-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-app-link-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-archive-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-configuration-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-diagnostic-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-payment-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-resources-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-sidebar-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-tags-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-talent-app-link-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-talents-invite-feature.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nClientProvider"] */ "/vercel/path0/packages/shared/i18n/src/provider/i18n-client.provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/button-link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/locale-switcher.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/router-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-decode-pathname-with-query-params.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-decode-pathname.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-router.ts");
